import { azlang, enlang } from "../language/lang";
const lsLang = localStorage.getItem("lang")
export const initialState = {
    students:[],
    lang: lsLang && lsLang == "en"?enlang: azlang,
    lang_id:1,
    content:{},
    internationalDiploma:[],
    studentsReviewsData:[],
    graduatsData:[]

}