import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import CloseSvg from '../img/svg/CloseSvg';
import UserSvg1 from '../img/svg/UserSvg1';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { postStudyAbroad } from '../actions/MainActions';
import { changeStateValue } from '../redux/MainReducer';
import PhoneSvg4 from '../img/svg/PhoneSvg4';

const StudyAbroadPopup = ({
  show, 
  onClose, 
  children, 
  country_name, 
  universty_name, 
  blogtitle, 
  contentData, 
  postStudyAbroad, 
  changeStateValue 
}) => {
  const [state, setState] = useState({
    name: '',
    surname: '',
    phone_number: '',
    country: country_name,
    university: universty_name,
    blog: '',
    successMessage: '',
    btnDisable: false,
    errors: {},
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleChangePhone = (phone_number) => {
    setState({ ...state, phone_number });
  };

  const handleSubmit = () => {
    const { name, phone_number, country, university, blog, surname } = state;
    let errors = {};

    if (!name) {
      errors.name = 'Adınızı daxil edin';
    }
    if (!surname) {
      errors.surname = 'Soyadınızı daxil edin';
    }
    if (!phone_number) {
      errors.phone = 'Nömrənizi daxil edin';
    }
    if (Object.keys(errors).length > 0) {
      setState({ ...state, errors });
      return;
    }

    const data = {
      name: name + " " + surname,
      phone_number,
      country,
      university,
      blog: blog || blogtitle,
    };

    setState({ ...state, btnDisable: true });

    postStudyAbroad(data)
      .then((resp) => {
        setState({ ...state, btnDisable: false });
        if (resp === "success") {
          changeStateValue({ name: "popUpVisible", value: true });
          onClose();
          setState({
            name: '',
            surname: '',
            country: '',
            university: '',
            phone_number: '',
            blog: '',
            errors: {},
          });
          console.log(data);
        }
      })
      .catch((error) => {
        setState({ ...state, successMessage: '', errors: { form: 'Form submission failed!' } });
      });
  };

  if (!show) {
    return null;
  }

  const { name, surname, phone_number, successMessage, btnDisable, errors } = state;

  return (
    <div className='popup-bg' onClick={onClose}>
      <div className='popup bg-[#fff] rounded-[8px] max-w-[600px] m-[16px] max-[400px]:max-w-[300px]' onClick={(e) => e.stopPropagation()}>
        {children}

        <div className='flex justify-between border-b-[2px]'>
          <div></div>
          <div className='flex justify-between w-[68%]'>
            <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px] max-[400px]:text-[18px] max-[400px]:p-[14px_0]'>
              {contentData?.get_information} Məlumat al
            </h3>
            <div className='flex items-start'>
              <button onClick={onClose} className='m-[7px]'>
                <CloseSvg />
              </button>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-[20px] p-[16px] max-[410px]:grid-cols-1'>
          <div className='flex flex-col gap-[8px]'>
            <div className='flex items-center gap-[3px]'>
              <div className='flex items-center gap-[5px]'>
                <div>
                  <UserSvg1 />
                </div>
                <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_name}Adınız</span>
              </div>
              {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
            </div>

            <div>
              <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                <input
                  className='w-full'
                  placeholder={'Adınız'}
                  name='name'
                  value={name}
                  onChange={handleChange}
                  id='name'
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-[8px]'>
            <div className='flex items-center gap-[3px]'>
              <div className='flex items-center gap-[5px]'>
                <div>
                  <UserSvg1 />
                </div>
                <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_surname}Soyadınız</span>
              </div>
              {errors.surname && <span className='text-red-500 text-[12px]'>{errors.surname}</span>}
            </div>

            <div className='flex flex-col'>
              <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                <input
                  className='w-full'
                  placeholder={'Soyadınız'}
                  name='surname'
                  value={surname}
                  onChange={handleChange}
                  id='surname'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col w-full gap-[5px] p-[0px_16px]'>
          <div className='flex flex-col gap-[5px]'>
            <div className='flex w-full items-center gap-[3px]'>
              <div className='flex items-center gap-[5px]'>
                <div>
                  <PhoneSvg4 />
                </div>
                <span className='text-[14px] font-[550] text-[#000]'>{contentData?.phone_number}Telefon nömrəniz</span>
              </div>
              {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
            </div>
          </div>

          <div className='border-[1px] w-full rounded-[8px]'>
            <PhoneInput
              containerStyle={{ border: "none", margin: "0" }}
              inputStyle={{ backgroundColor: "#f2f2f2", padding: '0px', marginLeft: '38px', borderRadius: "5px", border: 'none', background: 'none', width: '100%' }}
              buttonStyle={{ backgroundColor: "transparent", border: "none" }}
              dropdownStyle={{ borderRadius: '0px' }}
              country={'az'}
              value={phone_number}
              onChange={handleChangePhone}
              className="custom-flag"
            />
          </div>
        </div>

        <div className='max-[400px]:mt-[6px]'>
          {country_name !== undefined &&
            <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
              <span className='text-[13px] font-[550] text-[#000] mt-[3px]'>{contentData?.popup_interested_country}Maraqlandığınız fənn:</span>
              <span className='text-[14px] font-[550] text-[#032552]'>{country_name}</span>
            </div>
          }

          {universty_name !== undefined &&
            <div className='p-[0px_20px] w-full flex items-center gap-[6px]'>
              <span className='text-[13px] font-[550] text-[#000] mt-[2px]'>{contentData?.popup_interested_university}</span>
              <span className='text-[14px] font-[550] text-[#032552]'>{universty_name}</span>
            </div>
          }
        </div>

        <div>
          <div className='flex justify-center w-full p-[0px_12px] mt-[12px] mb-[20px]'>
            <button disabled={btnDisable} onClick={handleSubmit} className='bg-[#EF7000] w-full p-[12px_0px] text-white rounded-[8px] text-[15px] font-[400]'>
              {contentData?.get_information} Məlumat al
            </button>
          </div>

          {successMessage && (
            <div className='text-green-500 text-center mb-[20px]'>{successMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}

StudyAbroadPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  country_name: PropTypes.string,
  universty_name: PropTypes.string,
  blogtitle: PropTypes.string,
  contentData: PropTypes.object,
  postStudyAbroad: PropTypes.func.isRequired,
  changeStateValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ 
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});

const mapDispatchToProps = { postStudyAbroad, changeStateValue };

export default connect(mapStateToProps, mapDispatchToProps)(StudyAbroadPopup);
