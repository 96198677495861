import React, { useEffect } from 'react'
import SubjectCard from './SubjectCard'
import { getSubjects } from '../../actions/MainActions'
import { useDispatch, useSelector } from 'react-redux'

const CeltApDiploma = () => {
  const lang_id = useSelector(state => state.Data.lang_id)
  const Subjects = useSelector(state => state.Data.Subjects || [])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects(lang_id))
  }, [dispatch, lang_id])
  return (
    <div className='grid grid-cols-4 gap-[20px] mt-[26px] container max-[1400px]:grid-cols-3 max-[990px]:grid-cols-2 max-[770px]:grid-cols-1 max-[770px]:place-items-center'>
      {
        Subjects.slice(29, 47).map(data => (
          <SubjectCard
          key={data.id}
          subject={data.translate.title}
          background={data.image_full_url}
          />
        ))
      }
  </div>
  )
}

export default CeltApDiploma