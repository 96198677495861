import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SectionHeader from '../SectionHeader'
import Card19 from '../Card19'
import Card13 from '../Card13'
// import FaqSection from '../components/FaqSection'
import Card29 from '../Card29'
import { useDispatch, useSelector } from 'react-redux';
import { getContent, getGraduats, getIntDiploma, getStudents } from '../../actions/MainActions';
import GraduatedStudents from '../students/GraduatedStudents';
import GraduatesHomeStudents from './GraduatesHomeStudents';

const SingleSchools = () => {
  const [graduatsData, setGraduatsData] = useState([]);
  const [kidsEnglishTypeData, setKidsEnglishTypeData] = useState([]);
  const [contentData, setContentData] = useState({});
  const [faqs, setFaqs] = useState([]);
  const schoolType = 1; 
  // Diplomas
  const lang_id = useSelector(state => state.Data.lang_id);
  const internationalDiploma = useSelector(state => state.Data.internationalDiploma);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIntDiploma(lang_id));
  }, [dispatch, lang_id]);
  //Content
  const content = useSelector(state => state.Data.content);
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);
  return (
    <div className=' mt-0'>
      {schoolType === 1 && (
        <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center'>
          <div className='embed_header_video h-full mt-[10px] w-1/2 max-[850px]:w-full'>
            <iframe 
              src="https://www.youtube.com/embed/COaJU62GF6M?si=bzZ52Hrfl-spAF-F" 
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen>
            </iframe>
          </div>
          <Card29 
            title={content?.main_title}
            description={content?.home_title}
            text1={content?.home_description_1}
            text2={content?.home_description_2}
          />
        </section>
      )}
        <section className='bg-[#f5f7f7] mt-[52px] pb-[52px]'>
          <div className='container'>
            <SectionHeader section_header_title={'Diplomunu Seç'}/>
            <div className='grid grid-cols-3 gap-[32px] max-[1080px]:grid-cols-2 max-[690px]:grid-cols-1 max-[420px]:gap-[50px]'>
              {internationalDiploma.map((data, i) => (
                <Card19 
                  key={data?.id}
                  image={data?.image_full_url}
                  lvl_diploma={data?.translate?.title}
                  text={data?.translate?.text}
                />
              ))}
            </div>
          </div>
        </section>
        <section>
        <SectionHeader section_header_title={content?.graduates}/>
        <GraduatesHomeStudents/>
          <div className='flex justify-center mt-[12px]'>
            <Link to='/students'>
              <button className='text-[var(--blue)] p-[6px_16px] text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                {content?.btn_view_all}
              </button>
            </Link>
          </div>
        </section>
    </div>
  )
}

export default SingleSchools
