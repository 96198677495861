import React from 'react'
import PhoneSvg from '../../img/svg/PhoneSvg'

const PhoneButton = ({ phoneNumber, text }) => {
  return (
    <button className='border border-[var(--blue)] w-full rounded-[12px] p-[4px]'>
        <a className='flex items-center justify-center gap-[5px] space-grotesk' href={`tel:${phoneNumber}`}>
            <div className='p-[4px] bg-[var(--light-blue-3)] rounded-[50%]'>
                <PhoneSvg/>
            </div>
            {text}
        </a>
    </button>
  )
}

export default PhoneButton