import React, { useEffect } from 'react'
import MobileSvg from '../../img/svg/MobileSvg'
import PhoneSvg from '../../img/svg/PhoneSvg'
import LocationSvg from '../../img/svg/LocationSvg'
import FacebookSvg from '../../img/svg/FacebookSvg'
import InstagramSvg from '../../img/svg/InstagramSvg'
import WhatsappSvg from '../../img/svg/WhatsappSvg'
import WhatsAppButton from '../btn/WhatsAppButton'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../../actions/MainActions'
import PhoneButton from '../btn/PhoneButton'
import center_bg from '../../img/center_bg.jpg'


const CentersCard = ({center, mobile, phone, location_link, instagram_link, facebook_link, whatsapp_link}) => {
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);
  return (
    <div className='min-w-[300px] max-w-[576px]  rounded-[8px] text-center shadow-custom mt-[25px] max-[430px]:min-w-[300px]'>
        <div className='w-full h-[266px] bg-cover bg-center rounded-[8px_8px_0px_0px]' style={{backgroundImage: `url(${center_bg})`}}></div>
        <div className='p-[16px]'>
          <p className='text-[26px] text-[var(--black)] font-[700]'>{content?.main_title}</p>
          <p className='text-[26px] text-[var(--black)] font-[700] '>{center}</p>
          <div className='flex justify-center gap-[20px] mt-[32px] mb-[16px]'>
          <a className='w-[35px] h-[35px] bg-[var(--light-blue-3)] p-[5px] rounded-[17.5px]' href={instagram_link}><InstagramSvg/></a>
          <a className='w-[35px] h-[35px] bg-[var(--light-blue-3)] p-[5px] rounded-[17.5px]' href={facebook_link}><FacebookSvg fill={"#1e398f"}/></a>
              <a className='w-[35px] h-[35px] bg-[var(--light-blue-3)] p-[5px] rounded-[17.5px]' href={location_link}><LocationSvg/></a>
            </div>
          <div>
            <div className='flex gap-[18px] container pt-[16px] max-w'>
              <PhoneButton
              text={content?.call}
              phoneNumber={mobile}
              />
              <WhatsAppButton
              text={content?.message}
              url={whatsapp_link}
              />
            </div>
          </div>
        </div>
    </div>
  )
}

export default CentersCard