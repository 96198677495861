import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../img/logo.png';
import azerbaijan from '../../img/azerbaijan.png';
import uk from '../../img/uk.png';
import MenuBurger from '../../img/svg/MenuBurger';
import { useDispatch, useSelector } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { azlang, enlang } from '../../language/lang';
import { getContent } from '../../actions/MainActions';

const Navbar = () => {
  const lang = useSelector(state => state.Data.lang);
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  // console.log(content);

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[var(--white)] fixed navbar w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between container">
          <NavLink to={'/'}><img className='max-w-[180px] p-[25px_12px_20px]' src={logo} alt="Logo" /></NavLink>
          <div className="flex gap-[2rem] hidden md:flex">
            <NavLink className="text-[var(--black)] text-[16px] outline-none" to='/'>{content?.nav_1}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px] outline-none" to='/subjects'>{content?.nav_2}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px] outline-none" to='/students'>{content?.nav_3}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px] outline-none" to='/centers'>{content?.nav_4}</NavLink>
            <img className='max-w-[30px] h-[20px]' src={azerbaijan} alt="Flag" />
          </div>
          <div className="block md:hidden">
            <button onClick={toggleNavbar} className="outline-none text-white p-2 focus:outline-none">
              <MenuBurger />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="p-[0px_35px_10px] md:hidden">
          <div className="flex flex-col gap-[1rem]">
            <NavLink className="text-[var(--black)] text-[16px]" to='/'>{content?.nav_1}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to='/subjects'>{content?.nav_2}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to='/students'>{content?.nav_3}</NavLink>
            <NavLink className="text-[var(--black)] text-[16px]" to='/centers'>{content?.nav_4}</NavLink>
            <img
              // onClick={toggleFlag} 
              className='max-w-[30px] h-[20px]' src={azerbaijan} alt="Flag" />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
