import React, { useEffect, useState } from 'react';
import RightArrowSvg from '../img/svg/RightArrow';
import PopupComponent from '../components/PopupComponent';
import { connect, useDispatch, useSelector } from 'react-redux';
import DiplomsPopup from '../components/DiplomsPopup';
import { getContent } from '../actions/MainActions';

const Card19 = ({ lvl_diploma, image, kidsEnglishTypeData, text, getKidsEnglishType }) => {
    const [showPopup, setShowPopup] = useState(false);
    const lang_id = useSelector(state => state.Data.lang_id);
    const dispatch = useDispatch();
    const content = useSelector(state => state.Data.content);
    useEffect(() => {
      dispatch(getContent(lang_id));
    }, [dispatch, lang_id]);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <div className='shadow-lg p-[26px_32px] flex flex-col gap-[32px] rounded-[8px] bg-[#fff]'>

            <div className='w-full'>
                <img src={image} className='w-full h-[200px] rounded-[100px]' alt='student' />
            </div>

            <div className='flex flex-col gap-[15px]'>
                <h3 className='font-[600] text-[24px] ml-[4px] text-[#313e3b]'>
                    {lvl_diploma}
                </h3>

                <div className='flex flex-col justify-between bg-[#fff] p-[0px_10px]'>
                    <div className='card19text h-[250px]' dangerouslySetInnerHTML={{ __html: text }} />

                    <div className='flex justify-center'>
                        <button onClick={togglePopup} className='border-[2px]  p-[10px_25px] border-[var(--blue)] rounded-[50px] flex justify-center gap-[10px] items-center'>
                            <span className='font-[700] text-[16px] text-[var(--blue)]'>{content?.get_info}</span>
                            <RightArrowSvg fill='var(--blue)' width='20' height='20' />
                        </button>
                        <DiplomsPopup show={showPopup} onClose={togglePopup} />
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Card19;
