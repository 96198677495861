import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card4 from './Card4';
import { getstudentsReviewsData } from '../actions/MainActions';

const StudentReviews = ({ dataCount, grid_cols, company_id }) => {
  const dispatch = useDispatch();
  const studentsReviewsData = useSelector(state => state.Data.studentsReviewsData);
  const lang_id = useSelector(state => state.Data.lang_id);

  useEffect(() => {
    dispatch(getstudentsReviewsData(lang_id, 9, company_id));
  }, [dispatch, lang_id, dataCount]);


  // console.log(studentsReviewsData);
  const shuffleArray = (array) => {
    // Function to shuffle the given array
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Take only the first `dataCount` student reviews and shuffle them
//   const firstReviews = shuffleArray(studentsReviewsData.slice(0, dataCount));

  return (
    <div className='pt-[32px] pb-[52px]'>
      <div className={`grid-cols-3 grid container gap-[40px] max-[900px]:grid-cols-2 max-[550px]:grid-cols-1`}>
        {studentsReviewsData?.map((data, i) => (
          <Card4
            key={i}
            image={data?.image_full_url}
            name={data?.name}
            text={data?.text}
            raiting={data?.raiting}
            company_id={5}
          />
        ))}
      </div>
    </div>
  );
};

export default StudentReviews;
