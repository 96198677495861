import React, { useState } from 'react';

const CardContainer = () => {
    const initialCards = Array.from({ length: 100 }, (_, i) => ({ content: `Карточка ${i + 1}` }));
    const [cards, setCards] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const cardsPerLoad = 20;

    const loadMoreCards = () => {
        const nextIndex = Math.min(currentIndex + cardsPerLoad, initialCards.length);
        const nextCards = initialCards.slice(currentIndex, nextIndex);
        setCards(prevCards => [...prevCards, ...nextCards]);
        setCurrentIndex(nextIndex);
    };

    const unloadCards = () => {
        const prevIndex = Math.max(currentIndex - cardsPerLoad, 0);
        const remainingCards = cards.slice(0, prevIndex);
        setCards(remainingCards);
        setCurrentIndex(prevIndex);
    };

    return (
        <div>
            <div id="card-container">
                {cards.map((card, index) => (
                    <div key={index} className="card" style={{ margin: '10px', padding: '20px', border: '1px solid #ccc' }}>
                        {card.content}
                    </div>
                ))}
            </div>
            {currentIndex < initialCards.length && (
                <button onClick={loadMoreCards}>Прочитать больше</button>
            )}
            {currentIndex > 0 && (
                <button onClick={unloadCards}>Прочитать меньше</button>
            )}
        </div>
    );
};

export default CardContainer;
