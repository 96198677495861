import React from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./pages/HomePage";
import SubjectsPage from "./pages/SubjectsPage";
import StudentsPage from "./pages/StudentsPage";
import CentersPage from "./pages/CentersPage";
import Test from "./components/test";
import Footer from "./components/Footer";
import ScrollIcon from "./components/ScrollIcon";
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollIcon/>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/subjects" element={<SubjectsPage />} />
        <Route path="/students" element={<StudentsPage />} />
        <Route path="/centers" element={<CentersPage />} />
        <Route path="/test" element={<Test />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
