import React from 'react'
import WhatsappSvg from '../../img/svg/WhatsappSvg'

const WhatsAppButton = ({ url, text }) => {
  return (
    <a href={url} className='border border-[var(--blue)] w-full rounded-[12px] p-[4px] flex items-center justify-center gap-[5px] space-grotesk'>

            <div className='p-[4px] bg-[var(--light-blue-3)] rounded-[50%]'>
                <WhatsappSvg/>
            </div>
            {text}
    </a>
  )
}

export default WhatsAppButton