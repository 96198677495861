import React, { useEffect } from 'react'
import OptionsCard from './OptionsCard'
import OptionSvgSetOne from '../../img/svg/OptionsSvgSetOne'
import OptionSvgSetTwo from '../../img/svg/OptionsSvgSetTwo'
import OptionScgSetThree from '../../img/svg/OptionSvgSetThree'
import { useDispatch, useSelector } from 'react-redux'
import { getOptions } from '../../actions/MainActions'

const OptionsParent = () => {
  const lang = useSelector(state=> state.Data.lang)
  const lang_id = useSelector(state => state.Data.lang_id)
  const Options = useSelector( state => state.Data.Options || [])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions(lang_id))
  }, [dispatch, lang_id]);

  // console.log(Options);
  return (
    <div className='bg-[var(--blue)]'>
      <div className='container p-[50px_0px] grid grid-cols-3 max-[1000px]:grid-cols-1 max-[1000px]:place-items-center max-[1000px]:gap-[20px]'>
        {
          Options.map(data => (
            <OptionsCard
            key={data.id}
            icon={data.image_full_url}
            title={data.translate.title}
            text_1={data.translate.text}
            />
          ))
        }
        {/* <OptionsCard
        icon={<OptionSvgSetOne/>}
        title={lang.international_teachers_title}
        text_1={lang.international_teachers_description_1}
        text_2={lang.international_teachers_description_2}
        text_3={lang.international_teachers_description_3}
        />
        <OptionsCard
        icon={<OptionSvgSetTwo/>}
        title={lang.individual_approach_title}
        text_1={lang.individual_approach_description_1}
        text_2={lang.individual_approach_description_2}
        text_3={lang.individual_approach_description_3}
        />
        <OptionsCard
        icon={<OptionScgSetThree/>}
        title={lang.vip_classes_title}
        text_1={lang.vip_classes_description_1}
        /> */}
      </div>
    </div>
  )
}

export default OptionsParent