import React, { useEffect } from 'react'
import HomeHeader from '../components/home/HomeHeader'
import SectionHeader from '../components/SectionHeader'
import Students from '../components/home/Students'
import DiplomaParent from '../components/home/DiplomaParent'
import OptionsParent from '../components/home/OptionsParent'
import ProgramsParent from '../components/home/ProgramsParent'
import Subjects from '../components/home/Subjects'
import SubjectsBg from '../img/SubjectsBg.jpg'
import NextStepBg from '../img/NextStepBg.jpg'
import Comment from '../components/home/Comment'
import { useDispatch, useSelector } from 'react-redux'
import { getContent } from '../actions/MainActions'
import StudentSlider from '../components/home/StudentSlider'
import PopupComponent from '../components/Popup'
import FaqSection from '../components/FaqSection'
import SingleSchools from '../components/home/SingleSchools'
import StudentReviews from '../components/StudentReviews'


const HomePage = () => {
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);

  // const data = content || {};
  // console.log(content)
  return (
    <div className='pt-[152px]'>
      <SingleSchools/>
    {/* <HomeHeader/> */}
    
    {/* <Students /> */}
    {/* <StudentSlider/> */}
    {/* <SectionHeader section_header_title={content?.foreign_school_diplomas}/> */}
    {/* <DiplomaParent/> */}
    <SectionHeader section_header_title={content?.why_celt_school}/>
    <OptionsParent/>
    {/* <ProgramsParent/> */}
    {/* <Subjects
    bg_img={SubjectsBg}
    title={content?.choise_of_subjects_title}
    text_1={content?.choise_of_subjects_description_1}
    text_2={content?.choise_of_subjects_description_2}
    text_3={content?. choise_of_subjects_description_3}
    button_title={content?.choise_of_subjects_button}
    link={"/subjects"}
    /> */}
    {/* <Comment/> */}
    {/* <Subjects
    bg_img={NextStepBg}
    title={content?.application_title}
    text_1={content?.application_description_1}
    text_2={content?.application_description_2}
    text_3={content?.application_description_3}
    button_title={content?.application_button}
    link={"/centers"}
    bg_color={"rgba(0, 0, 0, 0.4)"}
    /> */}
    {/* <FaqSection/> */}
    <SectionHeader section_header_title={'Rəylər'}/>
    <StudentReviews/>
    </div>
  )
}

export default HomePage