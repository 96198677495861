import React, { useEffect, useState } from 'react'
import SubjectCardImg from '../../img/subjectCardImg.png'
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../../actions/MainActions';
import StudyAbroadPopup from '../StudyAbroadPopup';

const SubjectCard = ({subject, background}) => {
  const lang_id = useSelector(state => state.Data.lang_id);
  const content = useSelector(state => state.Data.content);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContent(lang_id));
  }, [dispatch, lang_id]);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(prevState => !prevState);
  };

  return (
    <div className='shadow rounded-[20px] max-w-[548px] min-w-[209px] flex flex-col justify-between bg-[var(--white)]'>
        <div className=" bg-cover bg-center h-64 w-full h-[220px] flex flex-col justify-end rounded-[20px_20px_0px_0px]" style={{backgroundImage: `url(${background})`}}>
            <img className='w-full ' src={SubjectCardImg}/>
        </div>
        <p className='p-[20px_30px] text-[20px] font-[700] text-[var(--black)]'>{subject}</p>
        <div className='flex justify-center m-[0px_0px_16px]'>
        <button onClick={togglePopup} className=' border-[1px] border-[#4F46E5] p-[4px_8px] rounded-[50px] font-[500] text-[14px] text-[#4F46E5] mt-[4px]'>
          {content?.apply}
        </button>

        <StudyAbroadPopup show={showPopup} country_name={subject} onClose={togglePopup} />
        </div>
    </div>
  )
}

export default SubjectCard